@import '../../../../scss/theme-bootstrap';

.homepage-formatter {
  &__rows {
    position: relative;
  }
  &__row {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    @include breakpoint($bp--large-up) {
      margin-bottom: 96px;
    }
  }
  &__trustmark {
    position: absolute;
    top: 40px;
    #{$rdirection}: 120px;
    z-index: 10;
  }
  &__trustmark-img {
    width: 80px;
    height: 80px;
  }
  .homepage-formatter__row {
    visibility: hidden;
  }
  .come-in {
    visibility: visible;
    transform: translateY(200px);
    animation: come-in 1s ease forwards;
  }
  .already-visible {
    transform: translateY(0);
    animation: none;
  }
  @keyframes come-in {
    to {
      transform: translateY(0);
    }
  }
  &-powder__kiss {
    @include breakpoint($bp--large-up) {
      width: 1280px;
      margin: 0 auto;
    }
  }
}
